<template>
  <div class="industry-information m-spill container">
    <h1 class="title border-none flex cen-start container">行业资讯</h1>
    <div class="industry-con">
        <div class="row cen-start industry-item " v-for="(item,index) in industryList" :key="index">
             <ng-image
                class="w-100 industry-img"
                :src="item.img"
                alt="thumbnail"
            ></ng-image>
            <div class="info ">
                <div class="info-title">{{item.title}}</div>
                <div class="info-author">
                    <span>{{item.time}}</span>
                    <span>{{item.author}}</span>
                </div>
                <div class="info-desc">{{item.desc}}</div>
            </div>
        </div>
     
    </div>
  </div>
</template>

<script>
import NgImage from "~/components/NgImage.vue";
import img1 from "@/assets/images/industry-img1.png";
import img2 from "@/assets/images/industry-img2.png";
import img3 from "@/assets/images/industry-img3.png";
import img4 from "@/assets/images/industry-img4.png";
import img5 from "@/assets/images/industry-img5.png";
import img6 from "@/assets/images/industry-img6.png";

export default {
  data() {
    return {
      industryList: [
        {
          img: img1,
          title: "网易云音乐与阿里音乐宣布达成版权互授合作",
          time: "2018-03-06",
          author: "作者：邹科",
          desc:
            "网易云音乐和阿里音乐共同宣布，达成了版权互授合作，此次互相授权后，大家在网易云音乐和虾米音乐除了可以听到…",
        },
        {
          img: img2,
          title: "致千万虾米音乐老友：你们的伤感，只是阿里的一枚弃子",
          time: "2021-01-05",
          author: "作者：李帅飞",
          desc:
            "虾米音乐，真的要关停了。2021年1月5日，虾米音乐在微博上宣布，因业务发展上的调整，将于2021年2月5日0点正式…",
        },
        {
          img: img3,
          title: "虾米 14 年：阿里巴巴的音乐梦和生意经",
          time: "2020-11-30",
          author: "作者：李帅飞",
          desc:
            "2020 有传言说：虾米音乐将于明年 1 月关闭，977年8月20日，旅行者2号探测器带着记录着地球上各种声音的唱盘…",
        },

        {
          img: img4,
          title: "Nest Audio：谷歌99美元的“音乐播放器”",
          time: "2018-03-06",
          author: "作者：邹科",
          desc:
            "国庆期间，谷歌对外发布了新品智能音箱Nest Audio。不同于国内智能音箱厂商纷纷将重点集中在了带屏智能音箱上…",
        },
        {
          img: img5,
          title: "复古独立不跟随，猫王新品发布会现场",
          time: "2018-03-06",
          author: "作者：邹科",
          desc:
            "977年8月20日，旅行者2号探测器带着记录着地球上各种声音的唱盘在肯尼迪航天中心成功发射升空。在相继拜访完木星…",
        },
        {
          img: img6,
          title: "向经典致敬！AI 假冒涅槃乐队发新歌在 YouTube 火了",
          time: "2018-03-06",
          author: "作者：邹科",
          desc:
            "从作曲到填词，俨然要成为一名专业音乐创作人。2017年，由AI作曲完成的世界首张AI专辑《I AM AI》发布后火遍全网…",
        },
      ],
    };
  },
  components: {
    NgImage,
  },

  computed: {},

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.industry-information {
  .title {
    height: 88px;
    line-height: 88px;
    border-bottom: 1px solid #e0e0e0;
  }
  .industry-con {
    .industry-item{
        margin-top: 40px;
    }
    .industry-img {
        width: 160px;
        height: 120px;
    }
    .info{
        height: 120px;
        margin-left: 30px;
        .info-title{
            font-size: 18px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
        }
        .info-author{
            margin-top: 38px;
        }
        .info-desc{
             margin-top: 17px;
        }
    }
  }
}
</style>
